import React from 'react';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import arrow from 'assets/images/arrow--black.svg';

import './view.scss';

export const ButtonsView = ({ name }) => (
    <>
        <button className={ `buttons__button buttons__button--next ${name}--next` } type="button">
            <Img className="button__icon" src={ arrow } alt="strzałka" />
        </button>
        <button className={ `buttons__button buttons__button--previous ${name}--previous` } type="button">
            <Img className="button__icon" src={ arrow } alt="strzałka" />
        </button>
    </>
);

ButtonsView.propTypes = {
    name: PropTypes.string.isRequired
};